<template>
  <div class="elv-financials-cell-topBottom-main">
    <div class="elv-financials-cell-topBottom-conent">
      <template v-if="props.params?.value?.cellName === 'Amount (In)'">
        <div
          v-if="props.params?.data?.amountInToken === null || props.params?.data?.direction === 'OUT'"
          class="elv-financials-cell-topBottom-main__empty"
        >
          -
        </div>
        <template v-else-if="props.params.node?.rowPinned === 'bottom'">
          <div
            class="elv-financials-cell-topBottom-content__top-header"
            :title="`$${props.params?.data?.fieldValues?.amountInUsd}`"
          >
            ${{ utils.formatNumber(props.params?.data?.fieldValues?.amountInUsd, 2) }}
          </div>
          <div
            class="elv-financials-cell-topBottom-content__bottom elv-financials-cell-topBottom-content__bottom-color-zoom"
          >
            {{ t('common.sum') }}
          </div>
        </template>
        <template v-else>
          <div class="elv-financials-cell-topBottom-content__top" :title="`$${amountInValue.value}`">
            ${{ amountInValue.formatValue }}
          </div>
          <div class="elv-financials-cell-topBottom-content__bottom" :title="amountInTokenTitleValue">
            {{ amountInTokenValue }}
            {{
              props.params?.data?.currency?.symbol ? props.params?.data?.currency?.symbol : props.params?.data?.currency
            }}
          </div>
        </template>
      </template>
      <template v-else-if="props.params?.value?.cellName === 'Amount (Out)'">
        <div
          v-if="props.params?.data?.amountOutToken === null || props.params?.data?.direction === 'IN'"
          class="elv-financials-cell-topBottom-main__empty"
        >
          -
        </div>
        <template v-else-if="props.params.node?.rowPinned === 'bottom'">
          <div
            class="elv-financials-cell-topBottom-content__top-header"
            :title="`$${props.params?.data?.fieldValues?.amountOutUsd}`"
          >
            ${{ utils.formatNumber(props.params?.data?.fieldValues?.amountOutUsd, 2) }}
          </div>
          <div
            class="elv-financials-cell-topBottom-content__bottom elv-financials-cell-topBottom-content__bottom-color-zoom"
          >
            {{ t('common.sum') }}
          </div>
        </template>
        <template v-else>
          <div class="elv-financials-cell-topBottom-content__top" :title="`$${amountOutValue.value}`">
            ${{ amountOutValue?.formatValue }}
          </div>
          <div class="elv-financials-cell-topBottom-content__bottom" :title="amountOutTokenTitleValue">
            {{ amountOutTokenValue }}
            {{
              props.params?.data?.currency?.symbol ? props.params?.data?.currency?.symbol : props.params?.data?.currency
            }}
          </div>
        </template>
      </template>
      <template v-else-if="props.params?.value?.cellName === 'Debit'">
        <div v-if="props.params?.data?.debitUsd === null" class="elv-financials-cell-topBottom-main__empty">-</div>
        <template v-else-if="props.params?.node?.group && props.params?.data?.debitUsd">
          <div class="elv-financials-cell-topBottom-content__top-header">
            ${{ utils.formatNumber(props.params?.data?.debitUsd, 2) }}
          </div>
        </template>
        <template v-else-if="props.params.node?.rowPinned === 'bottom'">
          <div
            class="elv-financials-cell-topBottom-content__bottom elv-financials-cell-topBottom-content__bottom-color-zoom"
          >
            {{ t('report.totalDebit') }}
          </div>
          <div class="elv-financials-cell-topBottom-content__top-header">
            ${{ utils.formatNumber(props.params?.data?.fieldValues?.debitUsd, 2) }}
          </div>
        </template>
        <template v-else>
          <div class="elv-financials-cell-topBottom-content__top">
            {{ utils.formatNumber(props.params?.data?.debitUsd, 2) }}
          </div>
          <div class="elv-financials-cell-topBottom-content__bottom">
            {{ props.params?.data?.debitAmount < Math.pow(10, -6) ? '0' : props.params?.data?.debitAmount }}
            {{ props.params?.data?.currency?.symbol }}
          </div>
        </template>
      </template>
      <template v-if="props.params?.value?.cellName === 'Credit'">
        <div v-if="props.params?.data?.creditUsd === null" class="elv-financials-cell-topBottom-main__empty">-</div>
        <template v-else-if="props.params?.node?.group && props.params?.data?.creditUsd">
          <div class="elv-financials-cell-topBottom-content__top-header">
            ${{ utils.formatNumber(props.params?.data?.creditUsd, 2) }}
            <!-- ${{ utils.formatNumber(props.params?.data?.debitUsd, 2) }} -->
          </div>
        </template>
        <template v-else-if="props.params.node?.rowPinned === 'bottom'">
          <div
            class="elv-financials-cell-topBottom-content__bottom elv-financials-cell-topBottom-content__bottom-color-zoom"
          >
            {{ t('report.totalCredit') }}
          </div>
          <div class="elv-financials-cell-topBottom-content__top-header">
            ${{ utils.formatNumber(props.params?.data?.fieldValues?.creditUsd, 2) }}
            <!-- ${{ utils.formatNumber(props.params?.data?.fieldValues?.debitUsd, 2) }} -->
          </div>
        </template>
        <template v-else>
          <div class="elv-financials-cell-topBottom-content__top">
            {{ utils.formatNumber(props.params?.data?.creditUsd, 2) }}
            <!-- {{ utils.formatNumber(props.params?.data?.debitUsd, 2) }} -->
          </div>
          <div class="elv-financials-cell-topBottom-content__bottom">
            {{ props.params?.data?.creditAmount < Math.pow(10, -6) ? '0' : props.params?.data?.creditAmount }}
            {{ props.params?.data?.currency?.symbol }}
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const cellHeight = computed(() => {
  return props.params.node?.rowPinned === 'bottom' ? '52px' : 'auto'
})

const cellMarginTop = computed(() => {
  return props.params.node?.rowPinned === 'bottom' ? '6px' : '0px'
})

const amountInValue = computed(() => {
  return props.params?.data?.direction === 'INTERNAL' || props.params?.data?.direction === 'IN'
    ? { formatValue: utils.formatNumber(props.params?.data?.amountUsd, 2), value: props.params?.data?.amountUsd }
    : { formatValue: utils.formatNumber(props.params?.data?.amountInUsd, 2), value: props.params?.data?.amountInUsd }
})

const amountOutValue = computed(() => {
  return props.params?.data?.direction === 'INTERNAL' || props.params?.data?.direction === 'OUT'
    ? { formatValue: utils.formatNumber(props.params?.data?.amountUsd, 2), value: props.params?.data?.amountUsd }
    : { formatValue: utils.formatNumber(props.params?.data?.amountOutUsd, 2), value: props.params?.data?.amountOutUsd }
})

const amountInTokenValue = computed(() => {
  let value = '0'
  if (props.params?.data?.amountInToken !== undefined) {
    // eslint-disable-next-line prefer-exponentiation-operator, no-restricted-properties
    value = props.params?.data?.amountInToken < Math.pow(10, -6) ? '0' : props.params?.data?.amountInToken
  } else {
    // eslint-disable-next-line prefer-exponentiation-operator, no-restricted-properties
    value = props.params?.data?.amount < Math.pow(10, -6) ? '0' : utils.formatNumber(props.params?.data?.amount, 2)
  }
  return value
})

const amountInTokenTitleValue = computed(() => {
  const value =
    props.params?.data?.amountInToken !== undefined ? props.params?.data?.amountInToken : props.params?.data?.amount
  const currency = props.params?.data?.currency?.symbol
    ? props.params?.data?.currency?.symbol
    : props.params?.data?.currency

  return `${value} ${currency}`
})

const amountOutTokenValue = computed(() => {
  let value = '0'
  if (props.params?.data?.amountOutToken !== undefined) {
    // eslint-disable-next-line prefer-exponentiation-operator, no-restricted-properties
    value = props.params?.data?.amountOutToken < Math.pow(10, -6) ? '0' : props.params?.data?.amountOutToken
  } else {
    // eslint-disable-next-line prefer-exponentiation-operator, no-restricted-properties
    value = props.params?.data?.amount < Math.pow(10, -6) ? '0' : utils.formatNumber(props.params?.data?.amount, 2)
  }
  return value
})

const amountOutTokenTitleValue = computed(() => {
  const value =
    props.params?.data?.amountOutToken !== undefined ? props.params?.data?.amountOutToken : props.params?.data?.amount
  const currency = props.params?.data?.currency?.symbol
    ? props.params?.data?.currency?.symbol
    : props.params?.data?.currency

  return `${value} ${currency}`
})
</script>
<style lang="scss">
.elv-financials-cell-topBottom-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: v-bind('cellHeight');
  margin-top: v-bind('cellMarginTop');

  .elv-financials-cell-topBottom-conent {
    margin-top: 6px;
    margin-bottom: 6px;
    text-align: right;

    .elv-financials-cell-topBottom-content__top-header {
      font-family: 'Barlow';
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: $elv-theme-base-text-color;
    }

    .elv-financials-cell-topBottom-content__top {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: $elv-theme-base-text-color;
    }

    .elv-financials-cell-topBottom-content__bottom {
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      zoom: 0.83;
      -moz-transform: scale(0.83);
      -moz-transform-origin: top left;
      color: $elv-color-838d95;
    }
  }

  .elv-financials-cell-topBottom-content__bottom-color-zoom {
    color: $elv-theme-tertiary-text-color;
    zoom: 0.91;
    -moz-transform: scale(0.91);
    -moz-transform-origin: top left;
  }

  .elv-financials-cell-topBottom-main__empty {
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $elv-theme-base-text-color;
    text-align: right;
  }
}
</style>
