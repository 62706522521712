<template>
  <div
    v-if="
      (props.type === 'to' && props.params?.data?.toAddress) ||
      (props.type === 'from' && props.params?.data?.fromAddress)
    "
    class="elv-financials-cell-fromTo-item-main"
    :data-type="props.type"
  >
    <template v-if="currentLogo.type === 'img'">
      <img
        v-if="currentLogo.logo !== ''"
        :onerror="useDefaultImage"
        :src="currentLogo.logo"
        class="elv-financials-cell-fromTo-item-main__logo"
        :data-type="props.type"
      />

      <img
        v-else
        :onerror="useDefaultImage"
        src="@/assets/img/default-logo.png"
        class="elv-financials-cell-fromTo-item-main__logo"
        :data-type="props.type"
      />
    </template>

    <SvgIcon
      v-else
      :name="currentLogo.logo"
      :data-type="props.type"
      width="14"
      height="14"
      class="elv-financials-cell-fromTo-item-main__logo"
    />
    <div
      class="elv-financials-cell-fromTo-main__address"
      :data-type="props.type"
      :style="{ fontFamily: currentText.font }"
    >
      {{ currentText.content }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { find, capitalize } from 'lodash-es'
import { reportContactType } from '@/config/reports'
import defaultImg from '@/assets/img/default-logo.png'
import { useReportStore } from '@/stores/modules/reports/index'

// eslint-disable-next-line no-unused-vars
const { t } = useI18n()
const reportStore = useReportStore()

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  },
  type: {
    type: String,
    required: true
  }
})

const currentLogo = computed(() => {
  const data = {
    logo: '',
    name: '',
    type: 'img'
  }
  switch (props.type) {
    case 'from':
      if (props.params?.data?.fromAddressContact?.role) {
        data.logo =
          find(reportContactType, {
            value: capitalize(props.params?.data?.fromAddressContact?.role)
          })?.icon ?? ''
        data.type = 'svg'
      } else if (props.params?.data?.fromEntityAccount) {
        data.logo = reportStore.entityDetail.logo
      } else if (props.params?.data?.fromAddress) {
        // eslint-disable-next-line no-nested-ternary
        data.logo = props.params?.data?.source?.platform?.logo ? props.params?.data?.source?.platform?.logo : ''
        if (props.params?.data?.source?.sourceName === 'everPay' && props.params?.data?.source?.type === 'API') {
          data.logo = 'https://static.elven.com/project/everpay.png'
        }
        if (props.params?.data?.source?.type === 'CSV' && !data.logo) {
          if (props.params?.data?.source?.csvFile?.templateType !== 'BANK') {
            data.logo = 'https://static.elven.com/icon/csv-icon.png'
          } else {
            data.logo = 'https://static.elven.com/bank/Other%20Bank.png'
          }
        }
      }
      break
    default:
      if (props.params?.data?.toAddressContact?.role) {
        data.logo =
          find(reportContactType, {
            value: capitalize(props.params?.data?.toAddressContact?.role)
          })?.icon ?? ''
        data.type = 'svg'
      } else if (props.params?.data?.toEntityAccount) {
        data.logo = reportStore.entityDetail.logo
      } else if (props.params?.data?.toAddress) {
        data.logo = props.params?.data?.source?.platform?.logo ? props.params?.data?.source?.platform?.logo : ''
        if (props.params?.data?.source?.sourceName === 'everPay' && props.params?.data?.source?.type === 'API') {
          data.logo = 'https://static.elven.com/project/everpay.png'
        }
        if (props.params?.data?.source?.type === 'CSV' && !data.logo) {
          if (props.params?.data?.source?.csvFile?.templateType !== 'BANK') {
            data.logo = 'https://static.elven.com/icon/csv-icon.png'
          } else {
            data.logo = 'https://static.elven.com/bank/Other%20Bank.png'
          }
        }
      }
      break
  }
  return data
})

const currentText = computed(() => {
  const data = {
    content: '',
    font: 'Plus Jakarta Sans' // 'Barlow'
  }
  switch (props.type) {
    case 'from':
      if (props.params?.data?.fromEntityAccount) {
        data.content =
          props.params?.data?.fromEntityAccount?.name !== ''
            ? props.params?.data?.fromEntityAccount?.name
            : utils.formatInputAddress(props.params?.data?.fromEntityAccount?.address)
        data.font = props.params?.data?.fromEntityAccount?.name ? 'Plus Jakarta Sans' : 'Barlow'
      } else if (props.params?.data?.fromAddressContact?.role) {
        data.content =
          props.params?.data?.fromAddressContact?.name !== ''
            ? props.params?.data?.fromAddressContact?.name
            : utils.formatInputAddress(props.params?.data?.fromAddressContact?.value)
        data.font = props.params?.data?.fromAddressContact?.name ? 'Plus Jakarta Sans' : 'Barlow'
      } else if (props.params?.data?.fromAddress) {
        data.content = utils.formatInputAddress(props.params?.data?.fromAddress)
        data.font = 'Barlow'
      }
      break
    default:
      if (props.params?.data?.toEntityAccount) {
        data.content =
          props.params?.data?.toEntityAccount?.name !== ''
            ? props.params?.data?.toEntityAccount?.name
            : utils.formatInputAddress(props.params?.data?.toEntityAccount?.address)
        data.font = props.params?.data?.toEntityAccount?.name ? 'Plus Jakarta Sans' : 'Barlow'
      } else if (props.params?.data?.toAddressContact?.role) {
        data.content =
          props.params?.data?.toAddressContact?.name !== ''
            ? props.params?.data?.toAddressContact?.name
            : utils.formatInputAddress(props.params?.data?.toAddressContact?.value)
        data.font = props.params?.data?.toAddressContact?.name ? 'Plus Jakarta Sans' : 'Barlow'
      } else if (props.params?.data?.toAddress) {
        data.content = utils.formatInputAddress(props.params?.data?.toAddress)
        data.font = 'Barlow'
      }
      break
  }
  return data
})

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}
</script>
<style lang="scss" scoped>
.elv-financials-cell-fromTo-item-main {
  cursor: pointer;
  background: $elv-color-EDF0F3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  border-radius: 12px;

  .elv-financials-cell-fromTo-item-main__logo {
    height: 14px;
    width: 14px;
  }

  .elv-financials-cell-fromTo-main__address {
    height: 16px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $elv-theme-base-text-color;
    padding-left: 4px;
  }
}
</style>
