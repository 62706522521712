<template>
  <div
    v-if="
      !props.params.node?.group && props.params.node?.rowPinned !== 'bottom' && props.params.data?.type !== 'GAIN_LOSS'
    "
    class="elv-financials-cell-fromTo-main"
  >
    <div>
      <fromToItem :params="props.params" type="from" />
    </div>
    <div class="elv-financials-cell-fromTo-middle">
      <img src="@/assets/img/arrow-right.png" alt="" style="width: 12px; height: 12px" />
    </div>
    <div>
      <fromToItem :params="props.params" type="to" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import fromToItem from './FromToItem.vue'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
</script>
<style lang="scss">
.elv-financials-cell-fromTo-main {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  .elv-financials-cell-fromTo-middle {
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>
