<template>
  <template v-if="isGroup">
    <GroupCell :params="props.params" />
  </template>
  <div v-else class="elv-financials-cell-date-time-main">
    <div class="elv-financials-cell-date-time-main-top">
      {{ dayjs.utc(props.params.data?.datetime).format(props.params.format || 'YYYY/MM/DD HH:mm') }}
    </div>
    <div
      v-if="props.params.data?.txHash"
      class="elv-financials-cell-date-time-main-bottom"
      @click="onClickedCopy(props.params.data?.txHash)"
    >
      <div class="elv-financials-cell-date-time-main-bottom__txHash" :data-clipboard-text="props.params.data?.txHash">
        TxHash : {{ utils.formatInputAddress(props.params.data?.txHash) }}
      </div>
      <img src="@/assets/img/copy.png" alt="" class="elv-financials-cell-date-time-main-bottom__copy" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import utc from 'dayjs/plugin/utc'
import GroupCell from './GroupCell.vue'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'

dayjs.extend(utc)
const { t } = useI18n()
const { toClipboard } = useClipboard()

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const isGroup = computed(() => {
  return props.params.node?.group && props.params.colDef.columnIndex === 0
})

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage(t('common.copied'))
  } catch (e) {
    console.error(e)
  }
}
</script>
<style lang="scss">
.elv-financials-cell-date-time-main {
  .elv-financials-cell-date-time-main-top {
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $elv-theme-base-text-color;
  }

  .elv-financials-cell-date-time-main-bottom {
    display: flex;
    align-items: center;
    cursor: pointer;

    .elv-financials-cell-date-time-main-bottom__txHash {
      height: 16px;
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 12px;
      zoom: 0.83;
      -moz-transform: scale(0.83);
      -moz-transform-origin: top left;
      line-height: 16px;
      color: $elv-color-838d95;
    }

    .elv-financials-cell-date-time-main-bottom__copy {
      padding-left: 4px;
      width: 10px;
      height: 10px;
    }
  }
}
</style>
