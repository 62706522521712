<template>
  <div
    v-if="!props.params.node?.group && props.params.node?.rowPinned !== 'bottom'"
    class="elv-financials-cell-event-main"
    :class="{ 'is-pinned': props.params?.pinned === 'right' }"
  >
    <el-popover
      v-if="props.params?.pinned === 'right'"
      ref="eventSelectRef"
      placement="bottom-start"
      trigger="click"
      popper-class="elv-financials-cell-event-popper"
      :show-arrow="true"
      :offset="0.2"
      @before-enter="onCheckSelectArrow(true)"
      @before-leave="onCheckSelectArrow(false)"
    >
      <template #reference>
        <div class="elv-financials-cell-event-select pinned">
          <div
            v-if="props.params?.data?.categoryNo !== '00000' && currentEvent"
            class="elv-financials-cell-event-main__title"
            :style="{ background: currentEventColor }"
          >
            <span>{{ utils.formatLanguageContent(isEmpty(currentEvent) ? '' : String(currentEvent)) }}</span>
          </div>
          <div v-else>{{ t('report.noSet') }}</div>

          <SvgIcon name="events-arrow" width="20" height="20" :style="{ transform: arrowRotate }" />
        </div>
      </template>

      <TransactionOverlayDropdown
        ref="eventDropdownRef"
        :drop-down-data="reportStore.eventDataList(eventDataScope)"
        :current-data="isShowEditDrawer ? props.params?.data : {}"
        :show-checkbox-input="false"
        :is-show-edit-drawer="isShowEditDrawer"
        @onCloseEventSelect="onCloseEventSelect"
      />
    </el-popover>

    <div v-else class="elv-financials-cell-event-select">
      <div
        v-if="props.params?.data?.categoryNo !== '00000' && currentEvent"
        class="elv-financials-cell-event-main__title"
        :style="{ background: currentEventColor }"
      >
        <span>{{ utils.formatLanguageContent(isEmpty(currentEvent) ? '' : String(currentEvent)) }}</span>
      </div>
      <div v-else>-</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { find, isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'
import { marketMapColorList } from '@/config'
import { reportEventList } from '@/config/reports'
import { useReportStore } from '@/stores/modules/reports/index'
import TransactionOverlayDropdown from '@/pages/Financials/Project/Transactions/components/OverlayDropdown.vue'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const { t } = useI18n()
const route = useRoute()
const eventSelectRef = ref()
const eventDropdownRef = ref()
const isShowEditDrawer = ref(true)
const reportStore = useReportStore()
const arrowRotate = ref('rotate(90deg)')

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const currentEvent = computed(() => {
  if (props.params?.data?.event) return utils.formatLanguageContent(props.params?.data?.event)
  return find(reportEventList, { categoryNo: props.params?.data?.categoryNo })?.categoryName
})

const currentEventColor = computed(() => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  if (props.params?.data?.eventColorIndex) return marketMapColorList[(props.params?.data?.eventColorIndex - 1) % 20]

  return marketMapColorList[
    // eslint-disable-next-line no-unsafe-optional-chaining
    find(reportEventList, { categoryNo: props.params?.data?.categoryNo })?.eventColorIndex % 20
  ]
})

const eventDataScope = computed(() => {
  let scope = 'all'
  if (props.params?.data?.direction !== 'INTERNAL') {
    scope = props.params?.data?.direction === 'OUT' ? 'out' : 'in'
  } else {
    scope = 'internal'
  }
  return scope
})

const onCloseEventSelect = async () => {
  try {
    unref(eventSelectRef)?.hide?.()
    const params = {
      activityIds: [props.params?.data?.activityId],
      categoryNo: String(eventDropdownRef.value?.currentCheckedEvent?.categoryNo)
    }
    reportStore.loading = true
    await ReportsApi.quickEditEvent(entityId.value, params)
    ElMessage.success(t('message.transactionCategorized'))
    reportStore.batchEditSelected = []
    // emitter.emit('resetList')
    // reportStore.fetchActivityEventList(entityId.value)
    emitter.emit('onDeleteTableRow', [props.params?.node?.id])

    const { data } = await ReportsApi.getActivityEvent(entityId.value, { status: 'REVIEWED' })
    const transactionFilter = {
      ...reportStore.transactionFilter,
      categoryNos: data
    }
    reportStore.processTableListDataInit(entityId.value, 'reviewed', {
      limit: 10,
      page: 1,
      ...transactionFilter
    })
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    reportStore.loading = false
  }
}

const onCheckSelectArrow = (status: boolean) => {
  arrowRotate.value = status ? 'rotate(-90deg)' : 'rotate(90deg)'
}
</script>
<style lang="scss">
.elv-financials-cell-event-main {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover .elv-financials-cell-event-select.pinned {
    opacity: 1;

    svg {
      fill: #000;
    }
  }

  .elv-financials-cell-event-main__title {
    height: 22px;
    border-radius: 2px;
    padding: 0 4px;

    span {
      color: $elv-color-FFFFFF;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      height: 22px;
      margin: 0;
      display: block;
      position: relative;
      top: -1px;
    }
  }
}

.elv-financials-cell-event-select {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.pinned {
    opacity: 0.6;
  }

  svg {
    fill: #d0d4d9;
    transition: transform 0.3s;
  }
}

.el-popover.elv-financials-cell-event-popper {
  padding: 0px;
}
</style>
