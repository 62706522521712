<template>
  <div class="elv-table-group-rows">
    <SvgIcon
      v-if="props.params.node?.level === 1 || props.params.node?.level === 2"
      class="elv-table-group-rows__arrow"
      name="group-arrow"
      width="18"
      height="18"
      :style="{ transform: rotate }"
      @click="onCheckGroupExpanded"
    />
    <div class="elv-table-group-rows-content">
      <div class="elv-table-group-rows-content__label">
        {{ utils.formatLanguageContent(props.params.node?.key) }}
      </div>
      <div class="elv-table-group-rows-content__total">
        {{
          props.params.data?.parentAccountName
            ? props.params.data?.parentAccountName
            : props.params.data?.addressCount
            ? props.params.data?.addressCount
            : props.params.data?.list?.length
        }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import utils from '@/lib/utils'

const props: any = defineProps<{
  params: any
}>()
const rotate = ref('rotate(0deg)')
const onCheckGroupExpanded = () => {
  rotate.value = props.params?.node.expanded ? 'rotate(0deg)' : 'rotate(90deg)'
  props.params?.node.setExpanded(!props.params?.node.expanded)
}

const contentPaddingLeft = computed(() => {
  return props.params.node?.level === 0 ? '0px' : '9px'
})

const contentBorderLeft = computed(() => {
  return props.params.node?.level === 0 ? '0px' : '1px'
})

watch(
  () => props.params?.node.expanded,
  () => {
    rotate.value = props.params?.node.expanded ? 'rotate(90deg)' : 'rotate(0deg)'
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
:deep(.elv-table-group-rows__arrow) {
  overflow: inherit;
  height: 42px;
  display: flex;
  margin-right: 7px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

:deep(.elv-table-group-rows) {
  display: flex;
}

:deep(.elv-table-group-rows-content) {
  display: flex;
  align-items: center;
  height: 42px;
  padding-left: v-bind('contentPaddingLeft');
  border-left: v-bind('contentBorderLeft') solid #e4e7eb;
}

:deep(.elv-table-group-rows-content__label) {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px 6px;
  gap: 12px;
  height: 24px;
  background: $elv-color-FFE5BE;
  border: 1px solid $elv-color-FFD89E;
  border-radius: 2px;
  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  color: $elv-color-0E1420;
}

:deep(.elv-table-group-rows-content__total) {
  font-family: 'Barlow';
  // margin-top: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: $elv-color-838d95;
  height: 24px;
  margin-left: 10px;
}
</style>
