<template>
  <template v-if="isGroup">
    <GroupCell :params="props.params" />
  </template>
  <template v-else>
    <div
      v-if="
        !props.params.node?.group &&
        props.params.node?.rowPinned !== 'bottom' &&
        ((props.params.data?.type !== 'GAIN_LOSS' && props.params?.value?.cellName === 'Source') ||
          props.params?.value?.cellName !== 'Source')
      "
      class="elv-financials-cell-currency-main"
    >
      <SvgIcon v-if="currentIcon === 'csv'" name="sources-csv" width="20" height="20" />
      <img
        v-else-if="currentIcon"
        :src="currentIcon"
        alt=""
        :onerror="useDefaultImage"
        class="elv-financials-cell-currency-main__logo"
      />
      <img
        v-else
        src="@/assets/img/default-logo.png"
        alt=""
        :onerror="useDefaultImage"
        class="elv-financials-cell-currency-main__logo"
      />
      <div class="elv-financials-cell-currency-main__title">{{ currentTitle }}</div>
    </div>
  </template>
</template>
<script lang="ts" setup>
import { find } from 'lodash-es'
import GroupCell from './GroupCell.vue'
import defaultImg from '@/assets/img/default-logo.png'
import { useReportStore } from '@/stores/modules/reports/index'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const reportStore = useReportStore()

const currentTitle = computed(() => {
  if (props.params.value?.cellName === 'Currency') {
    return props.params?.data?.currency?.symbol ? props.params?.data?.currency?.symbol : props.params?.data?.currency
  }
  if (props.params.value?.cellName === 'Source') {
    return props.params?.data?.source?.sourceName
  }
  if (props.params.value?.cellName === 'Assets') {
    return props.params?.data?.platform?.name
  }
  return ''
})

const currentIcon = computed(() => {
  if (props.params.value?.cellName === 'Currency') {
    const currency: any = find(reportStore.currencyList, { symbol: props.params?.data?.currency })
    return props.params?.data?.currency?.logo ? props.params?.data?.currency?.logo : currency?.logo ?? ''
  }
  if (props.params.value?.cellName === 'Source') {
    if (props.params?.data?.source?.sourceName === 'everPay' && props.params?.data?.source?.type === 'API')
      return 'https://static.elven.com/project/everpay.png'
    if (!props.params?.data?.source) {
      return defaultImg
    }

    return props.params?.data?.source?.platform?.logo ? props.params?.data?.source?.platform?.logo : 'csv'
  }
  if (props.params.value?.cellName === 'Assets') {
    return props.params?.data?.platform?.logo
  }
  return ''
})

const isGroup = computed(() => {
  return props.params.node?.group && props.params.colDef.columnIndex === 0
})

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}
</script>
<style lang="scss">
.elv-financials-cell-currency-main {
  display: flex;
  align-items: center;

  .elv-financials-cell-currency-main__logo {
    width: 20px;
    height: 20px;
  }

  .elv-financials-cell-currency-main__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $elv-theme-base-text-color;
    padding-left: 8px;
  }
}
</style>
