<template>
  <el-drawer
    v-model="isShowEditDrawer"
    :show-close="false"
    modal-class="elv-report-transaction-overlay"
    class="elv-report-transaction-drawer"
    :before-close="onCheckDrawerStatus"
    :size="400"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-report-transaction-drawer-header__title">
        {{ props.isBatch ? t('button.edit') : t('report.transaction') }}
      </h4>
      <SvgIcon
        name="close"
        width="24"
        height="24"
        class="elv-report-transaction-drawer-header__close"
        @click="onCheckDrawerStatus"
      ></SvgIcon>
    </template>
    <el-scrollbar>
      <div v-if="props.isBatch" class="elv-report-transaction-drawer-selected">
        <span>{{ t('common.selected') }}</span>
        <p>{{ t('report.transactionsTotal', { total: reportStore.batchEditSelected.length }) }}</p>
      </div>
      <div v-else class="elv-report-transaction-drawer-info">
        <p class="elv-report-transaction-drawer-info__title">{{ direction }}</p>
        <h3
          class="elv-report-transaction-drawer-info__total"
          :title="`${props.currentData?.amount}${props.currentData?.currency}`"
        >
          {{ utils.formatNumber(props.currentData?.amount, 2) }}{{ props.currentData?.currency }}
        </h3>
        <ul>
          <li class="elv-report-transaction-drawer-info__source">
            <span>{{ t('report.source') }}</span>
            <p>
              <template v-if="!props.isBatch && props.currentData?.type !== 'GAIN_LOSS'">
                <SvgIcon v-if="SourceLogo === 'csv'" name="sources-csv" width="14" height="14" />
                <img v-else :src="SourceLogo?.logo" :alt="SourceLogo?.name" /><span>{{ SourceName }}</span>
              </template>
              <template v-else>-</template>
            </p>
          </li>
          <li class="elv-report-transaction-drawer-transaction__detail">
            <span>{{ t('report.transactionDetail') }}</span>
            <div>
              <SvgIcon v-if="SourceLogo === 'csv'" name="sources-csv" width="14" height="14" />
              <img v-else :src="SourceLogo?.logo" :alt="SourceLogo?.name" />
              <p>{{ utils.formatInputAddress(props.currentData?.txHash) }}</p>
              <SvgIcon
                v-if="props.currentData?.source?.csvFile?.templateType !== 'BANK'"
                name="transaction-launch"
                width="12"
                height="12"
                @click="onOpenBrowser"
              />
            </div>
          </li>
          <li class="elv-report-transaction-drawer-info__dateTime">
            <span>{{ t('report.datetime') }}</span>
            <p>{{ datetime }}</p>
          </li>
          <li class="elv-report-transaction-drawer-info__amount">
            <span>{{ t('report.amount') }}</span>
            <p :title="`$ ${props.currentData?.amountUsd}`">$ {{ amountValue }}</p>
          </li>
          <li class="elv-report-transaction-drawer-info__from">
            <span>{{ t('report.from') }}</span>
            <template v-if="!props.isBatch && props.currentData?.type !== 'GAIN_LOSS'">
              <div
                v-if="
                  (props.currentData?.fromEntityAccount?.address || props.currentData?.fromAddress) &&
                  !props.currentData?.fromAddressContact?.role
                "
                class="elv-report-transaction-drawer-info__from-account"
              >
                <SvgIcon v-if="currentFromLogo === 'csv'" name="sources-csv" width="14" height="14" class="from_logo" />
                <img
                  v-else-if="currentFromLogo !== ''"
                  :src="currentFromLogo"
                  :alt="reportStore.entityDetail.name"
                  :onerror="useDefaultImage"
                />
                <img
                  v-else
                  :onerror="useDefaultImage"
                  src="@/assets/img/default-logo.png"
                  :alt="reportStore.entityDetail.name"
                />
                <span v-if="props.currentData?.fromEntityAccount?.name">{{
                  props.currentData?.fromEntityAccount?.name
                }}</span>
                <p
                  v-if="!props.currentData?.fromAddressContact?.name && !props.currentData?.fromEntityAccount?.name"
                  class="elv-report-transaction-drawer-info__from-address"
                  :data-clipboard-text="props.currentData?.fromAddress"
                >
                  {{ utils.formatInputAddress(props.currentData?.fromAddress) }}
                  <SvgIcon
                    name="sources-copy"
                    width="12"
                    height="12"
                    @click="onClickedCopy(props.currentData?.fromAddress)"
                  />
                </p>
              </div>

              <div
                v-if="props.currentData?.fromAddressContact?.role"
                class="elv-report-transaction-drawer-info__from-contact"
              >
                <span>{{ props.currentData?.fromAddressContact?.name }}</span>
                <p :style="{ color: contactValue(props.currentData?.fromAddressContact)?.color }">
                  <SvgIcon
                    :name="`${contactValue(props.currentData?.fromAddressContact)?.icon}`"
                    width="13"
                    height="13"
                  />
                  {{ contactValue(props.currentData?.fromAddressContact)?.value }}
                </p>
              </div>
              <p
                v-if="props.currentData?.fromAddressContact?.role || props.currentData?.fromEntityAccount?.address"
                class="elv-report-transaction-drawer-info__from-address"
                :data-clipboard-text="props.currentData?.fromAddress"
              >
                {{ utils.formatInputAddress(props.currentData?.fromAddress) }}
                <SvgIcon
                  name="sources-copy"
                  width="12"
                  height="12"
                  @click="onClickedCopy(props.currentData?.fromAddress)"
                />
              </p>
            </template>
            <template v-else>-</template>
          </li>
          <li class="elv-report-transaction-drawer-info__to">
            <span>{{ t('report.to') }}</span>
            <template v-if="!props.isBatch && props.currentData?.type !== 'GAIN_LOSS'">
              <div
                v-if="
                  (props.currentData?.toEntityAccount?.address || props.currentData?.toAddress) &&
                  !props.currentData?.toAddressContact?.role
                "
                class="elv-report-transaction-drawer-info__to-account"
              >
                <SvgIcon v-if="currentToLogo === 'csv'" name="sources-csv" width="14" height="14" class="to_logo" />
                <img v-else-if="currentToLogo !== ''" :src="currentToLogo" alt="logo" />
                <img
                  v-else
                  :onerror="useDefaultImage"
                  src="@/assets/img/default-logo.png"
                  :alt="reportStore.entityDetail.name"
                />
                <span v-if="props.currentData?.toEntityAccount?.name">{{
                  props.currentData?.toEntityAccount?.name
                }}</span>
                <p
                  v-if="!props.currentData?.toAddressContact?.name && !props.currentData?.toEntityAccount?.name"
                  class="elv-report-transaction-drawer-info__to-address"
                  :data-clipboard-text="props.currentData?.toAddress"
                >
                  {{ utils.formatInputAddress(props.currentData?.toAddress) }}
                  <SvgIcon
                    name="sources-copy"
                    width="12"
                    height="12"
                    @click="onClickedCopy(props.currentData?.toAddress)"
                  />
                </p>
              </div>
              <div
                v-if="props.currentData?.toAddressContact?.role"
                class="elv-report-transaction-drawer-info__to-contact"
              >
                <span>{{ props.currentData?.toAddressContact?.name }}</span>
                <p :style="{ color: contactValue(props.currentData?.toAddressContact)?.color }">
                  <SvgIcon
                    :name="`${contactValue(props.currentData?.toAddressContact)?.icon}`"
                    width="13"
                    height="13"
                  />
                  {{ contactValue(props.currentData?.toAddressContact)?.value }}
                </p>
              </div>
              <p
                v-if="props.currentData?.toAddressContact?.role || props.currentData?.toEntityAccount?.address"
                class="elv-report-transaction-drawer-info__to-address"
                :data-clipboard-text="props.currentData?.toAddress"
              >
                {{ utils.formatInputAddress(props.currentData?.toAddress) }}
                <SvgIcon
                  name="sources-copy"
                  width="12"
                  height="12"
                  @click="onClickedCopy(props.currentData?.toAddress)"
                />
              </p>
            </template>
            <template v-else>-</template>
          </li>
        </ul>
      </div>
      <div class="elv-report-transaction-drawer-event">
        <h4>
          {{ props.isBatch ? t('report.category') : t('report.accounting') }}
          <!-- <span> Automatic recognition</span> -->
        </h4>
        <el-popover
          ref="eventSelectRef"
          placement="bottom-start"
          trigger="click"
          popper-class="elv-transactions-drawer-event-popper"
          :show-arrow="true"
          :offset="0.2"
          :disabled="!props.isBatch && props.currentData?.type === 'GAIN_LOSS'"
          @before-enter="onCheckSelectArrow(true)"
          @before-leave="onCheckSelectArrow(false)"
        >
          <template #reference>
            <div
              class="elv-transactions-drawer-event-select"
              :class="{ 'is-disabled': !props.isBatch && props.currentData?.type === 'GAIN_LOSS' }"
            >
              <div
                v-if="
                  (currentEventData?.primaryGrouping &&
                    currentEventData?.subGrouping &&
                    currentEventData?.categoryName &&
                    currentEventData?.categoryNo !== '00000') ||
                  props.isBatch
                "
              >
                <template v-if="!isEmpty(currentEventData) && currentEventData?.categoryNo !== '00000'">
                  <p>{{ currentEventData?.primaryGrouping }} / {{ currentEventData?.subGrouping }}</p>
                  <span>{{ currentEventData?.categoryName }}</span>
                </template>
                <p
                  v-if="!isEmpty(currentEventData) && currentEventData?.categoryNo === '00000'"
                  class="elv-transactions-drawer-event-select__noSet"
                >
                  {{ t('report.noSet') }}
                </p>
              </div>
              <p v-else class="elv-transactions-drawer-event-select__noSet">{{ t('report.noSet') }}</p>
              <SvgIcon name="events-arrow" width="20" height="20" :style="{ transform: arrowRotate }" />
            </div>
          </template>

          <p v-if="!props.isBatch" class="elv-transactions-drawer-event-option-title">
            Showing all labels with&nbsp;<span>token flow: {{ tokenFlowDirection }}</span>
          </p>
          <TransactionOverlayDropdown
            ref="eventDropdownRef"
            :drop-down-data="reportStore.eventDataList(eventDataScope)"
            :current-data="isShowEditDrawer ? props.currentData : {}"
            :show-checkbox-input="false"
            :is-show-edit-drawer="isShowEditDrawer"
            @onCloseEventSelect="onCloseEventSelect"
          />
        </el-popover>
        <div
          class="elv-transaction-drawer-compute-preview"
          :style="{
            'background-color': isUpdateEvent ? '#F1F7E4' : '#F1F7E4'
          }"
        >
          <div v-if="isUpdateEvent && !isEmpty(divideData)" class="elv-transaction-drawer-compute-preview-info">
            <p>{{ t('report.accountingPreview') }}</p>
            <p>{{ t('report.accountingPreviewInfo') }}</p>
          </div>
          <ul v-if="transactionDetail.journalList.length || (isUpdateEvent && !isEmpty(divideData))">
            <li>
              <span class="elv-transaction-drawer-compute__direction title"></span>
              <p class="elv-transaction-drawer-compute__account title">{{ t('common.account') }}</p>
              <p class="elv-transaction-drawer-compute__debit title">{{ t('common.debit') }}</p>
              <p class="elv-transaction-drawer-compute__credit title">{{ t('common.credit') }}</p>
            </li>
            <li>
              <span class="elv-transaction-drawer-compute__direction">Dr</span>
              <p class="elv-transaction-drawer-compute__account">
                {{
                  isUpdateEvent
                    ? divideData?.debitAccount
                      ? divideData?.debitAccount
                      : currentAccountData(transactionDetail.journalList[0]?.accountId)
                    : currentAccountData(transactionDetail.journalList[0]?.accountId) ??
                      transactionDetail.journalList[0]?.accountName
                }}
              </p>
              <p class="elv-transaction-drawer-compute__debit">
                ${{
                  (transactionDetail.journalList[0]?.debitUsd
                    ? utils.formatNumber(transactionDetail.journalList[0]?.debitUsd, 2)
                    : utils.formatNumber(transactionDetail.journalList[1]?.debitUsd, 2)) || amountValue
                }}
              </p>
              <p class="elv-transaction-drawer-compute__credit">-</p>
            </li>
            <li>
              <span class="elv-transaction-drawer-compute__direction">Cr</span>
              <p class="elv-transaction-drawer-compute__account">
                {{
                  isUpdateEvent
                    ? divideData?.creditAccount
                      ? divideData?.creditAccount
                      : currentAccountData(transactionDetail.journalList[1]?.accountId)
                    : currentAccountData(transactionDetail.journalList[1]?.accountId) ??
                      transactionDetail.journalList[1]?.accountName
                }}
              </p>
              <p class="elv-transaction-drawer-compute__debit">-</p>
              <p class="elv-transaction-drawer-compute__credit">
                ${{
                  (transactionDetail.journalList[1]?.creditUsd
                    ? utils.formatNumber(transactionDetail.journalList[1]?.creditUsd, 2)
                    : utils.formatNumber(transactionDetail.journalList[0]?.creditUsd, 2)) || amountValue
                }}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <ul
        v-if="
          !props.isBatch &&
          currentEventData?.cryptoPayments &&
          transactionDetail.costing?.capitalGainLoss &&
          transactionDetail.costing?.costBasis
        "
        class="elv-report-transaction-drawer-loss"
      >
        <p class="elv-report-transaction-drawer-loss__title">{{ t('report.capitalGainORLoss') }}</p>
        <li>
          <p class="elv-report-transaction-drawer-loss__label">{{ t('report.marketValue') }}</p>
          <p class="elv-report-transaction-drawer-loss__value">
            <span>{{ utils.formatNumber(props.currentData?.amount, 2) }}{{ props.currentData?.currency }}</span
            >${{ amountValue }}
          </p>
        </li>
        <!-- <li>
          <p class="elv-report-transaction-drawer-loss__label">Fee Market Value</p>
          <p class="elv-report-transaction-drawer-loss__value"><span>0.0029ETH</span>$5.21</p>
        </li> -->
        <li>
          <p class="elv-report-transaction-drawer-loss__label">{{ t('report.Cost basis') }}</p>
          <p class="elv-report-transaction-drawer-loss__value">
            <template v-if="costBasisValue"> ${{ costBasisValue }} </template>
            <template v-else> - </template>
          </p>
        </li>
        <!-- <li>
          <p class="elv-report-transaction-drawer-loss__label">Fee Cost Basis</p>
          <p class="elv-report-transaction-drawer-loss__value">$1.49</p>
        </li> -->
        <li>
          <p class="elv-report-transaction-drawer-loss__label">{{ t('report.capitalGainORLoss') }}</p>
          <p class="elv-report-transaction-drawer-loss__value">
            <template v-if="transactionDetail.costing?.capitalGainLoss"
              >${{ utils.formatNumber(transactionDetail.costing?.capitalGainLoss, 2) }}
            </template>
            <template v-else> -</template>
          </p>
        </li>
      </ul>
      <Memo
        v-if="!props.isBatch && props.currentData?.type !== 'GAIN_LOSS'"
        ref="uploadMemoRef"
        v-loading="loading"
        :current-data="transactionDetail?.activity ?? {}"
      />
    </el-scrollbar>
    <template #footer>
      <el-button
        v-if="(!props.isBatch && props.currentData?.type !== 'GAIN_LOSS') || props.isBatch"
        :loading="saveLoading"
        class="elv-report-transaction-drawer-footer__save"
        @click="onSave"
      >
        {{ t('button.saveChanges') }}
      </el-button>
    </template>
  </el-drawer>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import Memo from './Memo.vue'
import utils from '@/lib/utils'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import ReportsApi from '@/api/ReportsApi'
import defaultImg from '@/assets/img/default-logo.png'
import { TransactionDetailType } from '#/ReportsTypes'
import TransactionOverlayDropdown from './OverlayDropdown.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import { map, uniq, find, orderBy, filter, indexOf, isEqual, isEmpty, capitalize } from 'lodash-es'
import { chainBrowserMap, reportEventList, reportEventDivideList, reportContactType } from '@/config/reports'

const props = defineProps({
  currentData: {
    type: Object,
    required: true
  },
  isBatch: {
    type: Boolean,
    default: false
  }
})

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const { toClipboard } = useClipboard()

const uploadMemoRef = ref()
const eventSelectRef = ref()
const eventDropdownRef = ref()
const saveLoading = ref(false)
const loading = ref(false)
const isShowEditDrawer = ref(false)
const transactionDetail = ref<TransactionDetailType>({
  costing: {
    costBasis: null,
    capitalGainLoss: null
  },
  journalList: [],
  activity: null
})
const arrowRotate = ref('rotate(90deg)')
// const emit = defineEmits(['resetList'])

const direction = computed(() => {
  let value = ''
  switch (props.currentData?.direction) {
    case 'OUT':
      value = t('report.sent')
      break
    case 'In':
      value = t('report.received')
      break
    default:
      value = t('report.internal')
      break
  }
  return value
})

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

const isUpdateEvent = computed(() => {
  return (
    (eventDropdownRef.value?.currentCheckedEvent?.categoryNo &&
      eventDropdownRef.value?.currentCheckedEvent?.categoryNo !== props.currentData?.categoryNo) ||
    props.currentData?.updatedCategoryNo
  )
})

const eventDataScope = computed(() => {
  let scope = 'all'
  if (!props.isBatch) {
    if (props.currentData?.direction !== 'INTERNAL') {
      scope = props.currentData?.direction === 'OUT' ? 'out' : 'in'
    } else {
      scope = 'internal'
    }
  } else if (props.isBatch && reportStore.batchEditSelected.length) {
    const isSameDirection = uniq(map(reportStore.batchEditSelected, 'direction')).length === 1
    if (isSameDirection && reportStore.batchEditSelected[0].direction !== 'INTERNAL') {
      scope = reportStore.batchEditSelected[0].direction === 'OUT' ? 'out' : 'in'
    } else {
      scope = 'internal'
    }
  }
  return scope
})

const amountValue = computed(() => {
  return props.currentData?.amountUsd
    ? utils.formatNumber(props.currentData?.amountUsd, 2)
    : props.currentData?.amountUsd
})
const costBasisValue = computed(() => {
  return transactionDetail.value.costing?.costBasis
    ? utils.formatNumber(transactionDetail.value.costing?.costBasis, 2)
    : transactionDetail.value.costing?.costBasis
})

const divideData = computed(() => {
  if (eventDropdownRef.value?.currentCheckedEvent?.categoryNo) {
    return reportEventDivideList.find((item: any) => {
      return item.categoryNo === eventDropdownRef.value?.currentCheckedEvent?.categoryNo
    })
  }
  return reportEventDivideList.find((item: any) => {
    return item.categoryNo === props.currentData?.categoryNo
  })
})

const datetime = computed(() => {
  return props.currentData?.datetime
    ? dayjs.utc(props.currentData?.datetime).format('YYYY/MM/DD HH:mm')
    : props.currentData?.datetime
})

const SourceName = computed(() => {
  return props.currentData?.source?.sourceName ? props.currentData?.source?.sourceName : ''
})

const SourceLogo = computed(() => {
  if (props.currentData?.source?.sourceName === 'everPay' && props.currentData?.source?.type === 'API')
    return { logo: 'https://static.elven.com/project/everpay.png', name: 'everPay' }
  if (!props.currentData?.source) return { logo: defaultImg, name: '-' }

  if (props.currentData?.source.type === 'CSV') return 'csv'

  return props.currentData?.source?.platform?.logo ? props.currentData?.source?.platform : 'csv'
})

const contactValue = computed(() => (data: any) => {
  return find(reportContactType, { value: capitalize(data?.role) })
})

const currentEventData = computed(() => {
  let data: any = {}
  if (props.isBatch) {
    if (eventDropdownRef.value?.currentCheckedEvent?.categoryName && isShowEditDrawer.value) {
      data = eventDropdownRef.value?.currentCheckedEvent
    } else {
      const isSameCategoryNo = uniq(map(reportStore.batchEditSelected, 'categoryNo')).length === 1
      if (isSameCategoryNo) {
        data = find(reportEventList, {
          categoryNo: reportStore.batchEditSelected[0].categoryNo
        })
        return data
      }
    }
  } else if (eventDropdownRef.value?.currentCheckedEvent?.categoryName) {
    data = eventDropdownRef.value?.currentCheckedEvent
  } else {
    data = props.currentData.categoryNo ? find(reportEventList, { categoryNo: props.currentData.categoryNo }) : {}
  }
  return data
})

const currentAccountData = computed(() => (accountId: string) => {
  return find(reportStore.allAccountList, { fieldId: accountId })?.name
})

const tokenFlowDirection = computed(() => {
  let directionValue = ''
  switch (currentEventData.value?.tokenDirection) {
    case 'in':
      directionValue = 'in'
      break
    case 'out':
      directionValue = 'out'
      break
    default:
      directionValue = '/'
      break
  }
  return directionValue
})

const currentFromLogo = computed(() => {
  const sourceLogo =
    // eslint-disable-next-line no-nested-ternary
    SourceLogo.value === 'csv'
      ? props.currentData?.source?.csvFile?.templateType !== 'BANK'
        ? 'csv'
        : 'https://static.elven.com/bank/Other%20Bank.png'
      : SourceLogo.value?.logo
  return props.currentData?.fromEntityAccount?.name ? reportStore.entityDetail.logo : sourceLogo
})

const currentToLogo = computed(() => {
  const sourceLogo =
    // eslint-disable-next-line no-nested-ternary
    SourceLogo.value === 'csv'
      ? props.currentData?.source?.csvFile?.templateType !== 'BANK'
        ? 'csv'
        : 'https://static.elven.com/bank/Other%20Bank.png'
      : SourceLogo.value?.logo
  return props.currentData?.toEntityAccount?.name ? reportStore.entityDetail.logo : sourceLogo
})

const onCheckDrawerStatus = () => {
  isShowEditDrawer.value = !isShowEditDrawer.value
}

defineExpose({ onCheckDrawerStatus })

const onSave = async () => {
  try {
    if (!currentEntityPermission.value?.transaction?.update) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    if (
      (eventDropdownRef.value?.currentCheckedEvent &&
        props.currentData.categoryNo !== eventDropdownRef.value?.currentCheckedEvent.categoryNo) ||
      (eventDropdownRef.value?.currentCheckedEvent &&
        !props.isBatch &&
        uploadMemoRef.value?.memo !== transactionDetail.value?.activity?.memo) ||
      !isEqual(transactionDetail.value?.activity?.attachments, uploadMemoRef.value?.fileList)
    ) {
      saveLoading.value = true
      let activityIdList = []
      if (props.isBatch) {
        activityIdList = map(reportStore.batchEditSelected, 'activityId')
      }
      const params: any = {
        categoryNo: eventDropdownRef.value?.currentCheckedEvent.categoryNo
      }
      if (props.isBatch) {
        params.activityIds = activityIdList
        await ReportsApi.quickEditEvent(entityId.value, params)
      } else {
        params.memo = uploadMemoRef.value?.memo
        params.activityAttachmentIds = map(uploadMemoRef.value?.fileList, 'activityAttachmentId')
        if (params.categoryNo === '00000') {
          delete params.categoryNo
        }
        await ReportsApi.editActivityDetail(entityId.value, props.currentData?.activityId, params)
      }
      const isOpenedToolPanel =
        route.name !== 'reports-transactions-duplicates' ? reportStore.agGridApi?.getOpenedToolPanel() : false

      if (route.name === 'reports-transactions-duplicates') {
        emitter.emit('resetDuplicateDataList')
        reportStore.fetchIssueDataList(entityId.value)
      } else if (route.name === 'reports-transactions-for-review') {
        if (props.isBatch || isOpenedToolPanel) {
          const rowIds = map(reportStore.batchEditSelected, 'rowId')
          emitter.emit('onDeleteTableRow', rowIds)
        } else if (params?.categoryNo) {
          emitter.emit('onDeleteTableRow', [props.currentData.rowId])
        }
        if (reportStore.batchEditSelected.length >= 50) {
          emitter.emit('resetList', !!isOpenedToolPanel)
        }
      } else if (props.isBatch) {
        emitter.emit('onEditTableRowEvent', {
          rows: reportStore.batchEditSelected,
          categoryNo: eventDropdownRef.value?.currentCheckedEvent.categoryNo
        })
        if (reportStore.batchEditSelected.length >= 50) {
          emitter.emit('resetList', !!isOpenedToolPanel)
        }
        reportStore.batchEditSelected = []
      } else {
        emitter.emit('onEditTableRowEvent', {
          rows: [props.currentData],
          categoryNo: eventDropdownRef.value?.currentCheckedEvent.categoryNo
        })
        // emitter.emit('resetList', !!isOpenedToolPanel)
      }

      if (isOpenedToolPanel && indexOf(reportStore.unSelectedList, props.currentData?.activityId) === -1) {
        reportStore.unSelectedList.push(props.currentData?.activityId)
      }

      if (props.isBatch || isOpenedToolPanel) {
        reportStore.batchEditSelected = []
      }

      if (!isOpenedToolPanel) {
        reportStore.batchEditSelected = []
      }
      ElMessage.success(t('message.editSuccess'))
      saveLoading.value = false
      if (reportStore.entityDetail.guideStep < 2) {
        reportStore.fetchEntityDetail(entityId.value)
      }
      onCheckDrawerStatus()
    }
  } catch (error: any) {
    ElMessage.error(error.message)
    saveLoading.value = false
    console.error(error)
  }
}

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('common.copied'))
  } catch (e) {
    console.error(e)
  }
}

const onCheckSelectArrow = (status: boolean) => {
  arrowRotate.value = status ? 'rotate(-90deg)' : 'rotate(90deg)'
}

const onCloseEventSelect = () => {
  unref(eventSelectRef)?.hide?.()
}

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

const onOpenBrowser = () => {
  const browserLink = chainBrowserMap[props.currentData?.source?.chain?.slug] ?? 'https://etherscan.io/tx/'
  window.open(
    // eslint-disable-next-line no-script-url
    'javascript:window.name;',
    // eslint-disable-next-line no-useless-escape, no-useless-concat
    '<script>location.replace("' + `${browserLink}/${props.currentData?.txHash}` + '")<\/script>'
  )
}

watch(
  () => eventDropdownRef.value?.currentCheckedEvent,
  () => {
    if (!isEmpty(eventDropdownRef.value?.currentCheckedEvent)) {
      transactionDetail.value.journalList = []
    }
  }
)

watch([() => props.currentData], () => {
  if (isShowEditDrawer.value && !isEmpty(props.currentData)) {
    nextTick(async () => {
      if (props.currentData?.activityId) {
        try {
          loading.value = true
          const { data } = await ReportsApi.getTransactionDetail(
            props.currentData?.entityId,
            props.currentData?.activityId
          )
          transactionDetail.value = data
          // 这里排序
          transactionDetail.value.journalList = orderBy(
            filter(data.journalList, (item: any) => item.grade === 'MAIN' && item.status === 'NORMAL'),
            'debitUsd',
            'desc'
          )
        } catch (error) {
          console.log(error)
        } finally {
          loading.value = false
        }
      }
    })
  }
})
</script>

<style lang="scss" scoped>
.elv-report-transaction-drawer {
  .elv-report-transaction-drawer-header__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0e0f11;
    margin: 0;
  }

  .elv-report-transaction-drawer-header__close {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-report-transaction-drawer {
  .el-drawer__header {
    padding: 26px 0px 18px;
    margin: 0 24px;
    border-bottom: 1px solid #edf0f3;
    height: 64px;
    box-sizing: border-box;
  }

  .el-drawer__body {
    padding: 16px 24px;
    margin-bottom: 16px;
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 24px;
  }

  .elv-report-transaction-drawer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 10px;
  }

  .elv-report-transaction-drawer-info {
    margin-bottom: 16px;

    .elv-report-transaction-drawer-info__title {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #838d95;
    }

    .elv-report-transaction-drawer-info__total {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      color: #1e2024;
    }

    ul {
      margin: 16px 0;
      border-top: 1px solid #edf0f3;
      display: flex;
      flex-wrap: wrap;

      li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50%;
        height: 52px;
        box-sizing: border-box;
        border-bottom: 1px solid #edf0f3;

        &:nth-child(2n) {
          padding-left: 8px;
          border-left: 1px solid #edf0f3;
        }

        &:nth-child(3),
        &:nth-child(4) {
          height: 49px;
        }

        &:nth-child(5),
        &:nth-child(6) {
          height: 67px;
        }

        span {
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 11px;
          line-height: 14px;
          color: #838d95;
        }
      }

      .elv-report-transaction-drawer-info__source {
        p {
          display: flex;
          align-items: center;
          margin-top: 2px;

          img {
            width: 14px;
            height: 14px;
            display: block;
          }

          span {
            margin-left: 5px;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;
          }
        }
      }

      .elv-report-transaction-drawer-transaction__detail {
        > div {
          display: flex;
          align-items: center;
          margin-top: 4px;
        }

        img {
          width: 14px;
          height: 14px;
          display: block;
        }

        p {
          margin-left: 5px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #1e2024;
          margin-right: 4px;
        }

        svg {
          cursor: pointer;
        }
      }

      .elv-report-transaction-drawer-info__dateTime,
      .elv-report-transaction-drawer-info__amount {
        p {
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #0e0f11;
          margin-top: 2px;
        }
      }

      .elv-report-transaction-drawer-info__from {
        .elv-report-transaction-drawer-info__from-account {
          display: flex;
          align-items: center;
          margin-top: 2px;

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;
          }

          img {
            width: 14px;
            height: 14px;
            display: block;
            margin-right: 4px;
          }

          .from_logo {
            margin-right: 4px;
          }

          .elv-report-transaction-drawer-info__from-address {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;

            svg {
              cursor: default;
            }
          }
        }

        .elv-report-transaction-drawer-info__from-contact {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 2px;

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;
          }

          p {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;
            padding-right: 8px;

            svg {
              margin-right: 2px;
            }
          }
        }

        .elv-report-transaction-drawer-info__to-contact {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 2px;

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;
          }

          p {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;

            svg {
              margin-right: 2px;
            }
          }

          .elv-report-transaction-drawer-info__to-address {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;

            svg {
              cursor: default;
            }
          }
        }

        .elv-report-transaction-drawer-info__from-address {
          margin-top: 2px;
          display: flex;
          align-items: center;
          font-family: 'Barlow';
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #838d95;

          svg {
            margin-left: 4px;
            cursor: pointer;
          }
        }
      }

      .elv-report-transaction-drawer-info__to {
        .elv-report-transaction-drawer-info__to-contact {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 2px;

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;
          }
        }

        .elv-report-transaction-drawer-info__to-account {
          display: flex;
          align-items: center;
          margin-top: 2px;

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;
          }

          img {
            width: 14px;
            height: 14px;
            display: block;
            margin-right: 4px;
          }

          .to_logo {
            margin-right: 4px;
          }

          .elv-report-transaction-drawer-info__to-address {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;

            svg {
              cursor: default;
            }
          }
        }

        p {
          margin-top: 2px;
          display: flex;
          align-items: center;
          font-family: 'Barlow';
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #838d95;

          svg {
            margin-left: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .elv-report-transaction-drawer-selected {
    width: 100%;
    padding-bottom: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #edf0f3;
    margin-bottom: 16px;

    span {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #838d95;
    }

    p {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #1e2024;
    }
  }

  .elv-report-transaction-drawer-event {
    background: #f9fafb;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 16px;

    h4 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #0e0f11;

      span {
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #aab4cf;
      }
    }

    .elv-transaction-drawer-compute-preview {
      border-radius: 4px;

      .elv-transaction-drawer-compute-preview-info {
        box-sizing: border-box;
        width: 100%;
        padding: 6px 12px 0;
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: #1e2024;
        margin-bottom: 6px;

        p:first-of-type {
          font-weight: 700;
          margin-bottom: 2px;
        }
      }
    }

    .elv-transactions-drawer-event-select {
      box-sizing: border-box;
      height: 53px;
      width: 100%;
      padding: 10px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      margin: 8px 0;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition-property: border, box-shadow;

      .elv-transactions-drawer-event-select__noSet {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #d0d4d9;
      }

      > div {
        display: flex;
        flex-direction: column;

        p {
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 11px;
          line-height: 15px;
          color: #aaafb6;
          margin-bottom: 8px;
        }

        span {
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          color: #0e0f11;
        }
      }

      &:not(.is-disabled):hover {
        cursor: pointer;
        border: 1px solid #5e85eb;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

        svg {
          fill: #5e85eb;
        }
      }

      svg {
        fill: #838d95;
        transition: transform 0.3s;
      }
    }

    ul {
      background: #ffffff;
      border: 1px solid #ced7e0;
      border-radius: 4px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #0e0f11;

      li {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border-top: 1px solid #edf0f3;

        &:first-child {
          height: 28px;
          border-top: 0px;
        }
      }

      .elv-transaction-drawer-compute__direction {
        height: 100%;
        width: 32px;
        border-right: 1px solid #edf0f3;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .elv-transaction-drawer-compute__account {
        width: 174px;
        height: 100%;
        padding: 5px 10px;
        box-sizing: border-box;
        border-right: 1px solid #edf0f3;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;

        &.title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #0e0f11;
        }
      }

      .elv-transaction-drawer-compute__debit {
        border-right: 1px solid #edf0f3;
      }

      .elv-transaction-drawer-compute__debit,
      .elv-transaction-drawer-compute__credit {
        width: 66px;
        height: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        overflow: hidden;
        word-break: break-word;

        &.title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #0e0f11;
        }
      }
    }
  }

  .elv-report-transaction-drawer-loss {
    box-sizing: border-box;
    padding: 12px 8px;
    width: 100%;
    background: #f9fafb;
    border-radius: 5px;
    margin-bottom: 16px;

    .elv-report-transaction-drawer-loss__title {
      width: 100%;
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #0e0f11;
      padding-bottom: 10px;
      border-bottom: 1px solid #dde1e6;
      margin-bottom: 10px;
    }

    li {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .elv-report-transaction-drawer-loss__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #1e2024;
      }

      .elv-report-transaction-drawer-loss__value {
        font-family: 'Barlow';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;

        span {
          padding-right: 6px;
          border-right: 1px solid #dde1e6;
          color: #838d95;
          margin-right: 5px;
        }
      }
    }
  }

  .elv-report-transaction-drawer-footer__save {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    gap: 10px;
    height: 36px;
    background: #1753eb;
    border: 0px;
    box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #0048ff;
    border-radius: 3px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    box-sizing: border-box;
    cursor: pointer;
  }
}

.el-popper.elv-transactions-drawer-event-popper {
  .elv-transactions-drawer-event-option-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #edf0f3;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;

    span {
      font-weight: 600;
      color: #0e0f11;
    }
  }
}
</style>
